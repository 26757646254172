import styled, { css } from "styled-components";
import { COLORS, fontMixin, fontMixins, phoneQuery } from "../../../styles";

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 20px 40px;
	background: ${COLORS.White};
	border-radius: 10px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
	margin-bottom: 11px;

	&:last-child {
		margin-bottom: 0;
	}

	${phoneQuery(css`
		margin-inline: 15px;
	`)}
`;

export const Title = styled.div`
	display: flex;
	align-items: center;
	${fontMixin({ size: 18, weight: 700 })};
	line-height: 22px;
	min-height: 30px;
	margin-bottom: 25px;

	span {
		font-weight: 400;
	}

	${phoneQuery(css`
		align-items: start;
		flex-direction: column;

		${fontMixins(16, 700)}
		span {
			display: inline-block;
		}
	`)}
`;

export const ContentBlockArrowPrev = styled.button`
	position: absolute;
	width: 30px;
	height: 40px;
	left: 0;
	top: 70px;
	border: 0;
	border-radius: 0 10px 10px 0;
	background: ${COLORS.Red};
	color: ${COLORS.White};
	cursor: pointer;

	&:after {
		content: "";
		position: relative;
		top: 2px;
		right: 0px;
		border: solid ${COLORS.White};
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 5px;
		transform: rotate(135deg);
	}

	${phoneQuery(css`
		top: 20px;
	`)}
`;

export const ContentBlockArrowNext = styled.button`
	position: absolute;
	width: 30px;
	height: 40px;
	right: 0;
	top: 70px;
	border: 0;
	border-radius: 10px 0 0 10px;
	background: ${COLORS.Red};
	color: ${COLORS.White};
	cursor: pointer;

	&:after {
		content: "";
		position: relative;
		top: 2px;
		right: 0px;
		border: solid ${COLORS.White};
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 5px;
		transform: rotate(315deg);
	}

	${phoneQuery(css`
		top: 20px;
	`)}
`;
